<template>
  <header
    class="header-main"
    :class="{ 'default-page': !headerSettings.isBackgroundImage }"
  >
    <Popup />

    <yt-popup
      v-model="helpMePopupOpened"
      size="sm"
      class=""
      @close="helpMeClose"
    >
      <slot>
        <component
          :is="getComponentName"
          @loaded="loadedHandler"
        >
        </component>
        <preloader :visible="!popupLoaded" />
      </slot>
    </yt-popup>
    <nav
      class="header-navigation"
      :class="{ active: menuActive || isNotifyPopupOpenMobile }"
    >
      <!--  Mobile menu background  -->
      <div
        class="header-mobile-background"
        :class="{ active: menuActive }"
      />
      <div class="container">
        <!--  Mobile menu area  -->
        <div class="header-menu-wrapper">
          <HeaderBurger
            class="burger-menu"
            :isActive="menuActive"
            :isBackgroundImage="headerSettings.isBackgroundImage"
            @menu-click="menuActive = !menuActive"
          />
          <a
            :class="{
              logo: isRu,
              'logo-ts': isEn,
            }"
            :href="
              _isNewDashboard ||
              (user.userRole === 'Expert' && changeMode.isExpertMode)
                ? '/lk/dashboard'
                : '/'
            "
          >
            <Icon :icon="logoIcon" />
          </a>

          <!-- Location selector-->
          <div class="search-tour-wrapper" v-if="isShowSearch">
            <TourSelector
              class="header-tour-selector"
              place="header"
              :autofocus="false"
              @isOpen="isInputOpen = $event"
              @updateLocation="submit($event)"
            />
            <form
              class="header-location-form"
              ref="form"
              autocomplete="off"
              :action="
                location.type !== 'distance' ? `/tours/${locationQueryParams}` : '/tours/'
              "
              :method="location.type !== 'distance' ? 'post' : 'get'"
            >
              <template v-if="location.type === 'distance'">
                <input
                  type="text"
                  class="hide"
                  name="loc[0][type]"
                  :value="location.type"
                />
                <input
                  type="text"
                  class="hide"
                  name="loc[0][lat]"
                  :value="location.lat"
                />
                <input
                  type="text"
                  class="hide"
                  name="loc[0][lon]"
                  :value="location.lon"
                />
                <input
                  type="text"
                  class="hide"
                  name="loc[0][radius]"
                  :value="location.radius"
                />
              </template>
              <input
                v-else
                type="text"
                class="hide"
                :name="location.type"
                :value="location.id"
              />
              <button
                v-if="!isInputOpen"
                type="submit"
                class="mobile-location-search-btn"
              />
            </form>
          </div>
        </div>

        <div class="header-navigation-wrapper">
          <!-- Header buttons -->
          <div class="header-buttons">
            <HeaderButton
              v-for="headerButton in headerButtons"
              :key="headerButton.id"
              class="dropdown-button"
              :data-test="headerButton.slug"
              :activeTitle="headerButton.title || '#TAB_ITEM'"
              :link="headerButton.link"
              :ajaxData="
                !!headerButton['on_click'] ? headerButton['on_click'] : ''
              "
              :type="headerButton.type"
              :isBackgroundImage="headerSettings.isBackgroundImage"
              @click-helpme="helpMeOpen"
            />
          </div>

          <!-- Tabs list -->
          <HeaderButton
            v-for="tabItem of mainMenu"
            :key="tabItem.id"
            v-show="true"
            class="dropdown-button"
            :activeTitle="tabItem.title || '#TAB_ITEM'"
            dropdownPosition="right"
            :type="tabItem.type"
            :arrow="tabItem.options.length > 0"
            :link="tabItem.link"
            :data-test="tabItem.slug"
            :ajaxData="tabItem.ajax"
            :isDesktop="tabItem.isDesktop"
            :isBackgroundImage="headerSettings.isBackgroundImage"
          >
            <template v-if="tabItem.options.length > 0" v-slot:dropdown>
              <div class="dropdown-list">
                <a
                  v-for="tabItemOption of tabItem.options"
                  :key="tabItemOption.id"
                  :href="tabItemOption.link"
                  :rel="tabItemOption.rel"
                  :data-test="tabItemOption.slug"
                  class="dropdown-item dropdown-title"
                  @click="dropDownMenuItemClick(tabItemOption, $event)"
                >
                  {{ tabItemOption.title || "MENU_ITEM" }}
                </a>
              </div>
            </template>
          </HeaderButton>

          <!-- Functional buttons -->
          <HeaderButton
            v-for="functionMenu of functionalButtons"
            :key="functionMenu.id"
            class="dropdown-button"
            :data-test="functionMenu.slug"
            :activeTitle="functionMenu.title || '#FUNCTION_ITEM'"
            :addCustomClass="functionMenu.addCustomClass"
            dropdownPosition="right"
            :isBackgroundImage="headerSettings.isBackgroundImage"
            :arrow="true"
          >
            <template v-slot:dropdown>
              <div class="dropdown-list">
                <div
                  v-for="functionMenuItem in functionMenu.options"
                  :key="functionMenuItem.id"
                  :data-test="functionMenuItem.slug"
                  class="dropdown-item dropdown-title"
                  @click="
                    linkClick(functionMenuItem.link, functionMenuItem.key)
                  "
                >
                  {{ functionMenuItem.title || "#FUNCTION_ITEM" }}
                  <span v-if="functionMenuItem.value" class="dropdown-value">
                    {{ functionMenuItem.value }}
                  </span>
                </div>
              </div>
            </template>
          </HeaderButton>

          <!--  User personal navigation  -->
          <div v-if="user.isLoggedIn" class="user-personal-navigation">
            <div class="user-area">
              <!-- User notifications  -->
              <div
                class="user-notifications"
                v-if="!menuActive && user.userRole === 'Expert'"
              >
                <div
                  class="user-menu-btn user-notifications-btn"
                  @click.stop="openNotificationPopup"
                >
                  <Icon
                    icon="icon-bell"
                    class="icon-transparent"
                    :color="
                      headerSettings.isBackgroundImage
                        ? 'neutral-100'
                        : 'violet-1'
                    "
                  />
                  <div
                    v-if="notifications.length > 0"
                    class="user-new-notifications"
                    :class="[
                      !headerSettings.isBackgroundImage &&
                      !isNotifyPopupOpenMobile
                        ? 'notifications-round-teal'
                        : 'notifications-round-white',
                    ]"
                  />
                </div>
                <div
                  class="user-menu-btn user-notifications-btn mobile"
                  @click.stop="openNotificationPopupMobile"
                >
                  <Icon
                    icon="icon-bell"
                    class="icon-transparent"
                    :color="
                      isNotifyPopupOpenMobile
                        ? 'gold-400'
                        : headerSettings.isBackgroundImage && !menuActive
                        ? 'neutral-100'
                        : headerSettings.isBackgroundImage && menuActive
                        ? 'neutral-100'
                        : !isBackgroundImage && menuActive
                        ? 'neutral-100'
                        : 'violet-1'
                    "
                  />

                  <!--                  notifications-round-white notifications-round-tea-->
                  <div
                    v-if="notifications.length > 0"
                    class="user-new-notifications"
                    :class="[
                      !headerSettings.isBackgroundImage &&
                      !isNotifyPopupOpenMobile
                        ? 'notifications-round-teal'
                        : 'notifications-round-white',
                    ]"
                  />
                </div>
                <popupNotifications
                  :isOpen="isNotifyPopupOpen || isNotifyPopupOpenMobile"
                  :notifications="notifications"
                  v-click-outside="closeNotificationPopup"
                  @accept="acceptNotification"
                  @acceptAll="acceptAllNotification"
                />
              </div>
              <!-- User favorites  -->
              <div
                class="user-favorites"
                v-if="!_isNewDashboard"
                @click="trackEvent('Header', 'Click Favorites')"
              >
                <a
                  class="user-menu-btn user-favorites-btn"
                  href="/lk/mytours/favorites/"
                >
                  <Icon
                    icon="icon-favorites"
                    class="icon-transparent"
                    :color="
                      isNotifyPopupOpenMobile
                        ? 'violet-1'
                        : headerSettings.isBackgroundImage && !menuActive
                        ? 'neutral-100'
                        : headerSettings.isBackgroundImage && menuActive
                        ? 'neutral-100'
                        : !isBackgroundImage && menuActive
                        ? 'neutral-100'
                        : 'violet-1'
                    "
                  />
                  <no-ssr>
                    <div
                      v-if="+user.favoritesCount > 0"
                      class="user-notification-counter"
                    >
                      {{ user.favoritesCount }}
                    </div>
                  </no-ssr>
                </a>
              </div>

              <!-- User messages  -->
              <div
                class="messages"
                @click="trackEvent('Header', 'Click Chats')"
              >
                <a
                  class="user-menu-btn user-messages-btn"
                  :href="menu.chat.link || '#'"
                >
                  <Icon
                    icon="icon-user-message"
                    class="icon-transparent"
                    :color="
                      isNotifyPopupOpenMobile
                        ? 'violet-1'
                        : headerSettings.isBackgroundImage && !menuActive
                        ? 'neutral-100'
                        : headerSettings.isBackgroundImage && menuActive
                        ? 'neutral-100'
                        : !isBackgroundImage && menuActive
                        ? 'neutral-100'
                        : 'violet-1'
                    "
                  />
                  <no-ssr>
                    <div
                      v-if="+user.messagesCount > 0"
                      class="user-notification-counter"
                    >
                      {{ user.messagesCount }}
                    </div>
                  </no-ssr>
                </a>
              </div>

              <!--  User login  -->
              <div class="user-login" v-if="!_isNewDashboard">
                <a
                  class="user-menu-btn user-login-btn"
                  :href="!isMobileHeader && (menu.account.link || '#')"
                  @click="handleUserClick()"
                  :color="
                    headerSettings.isBackgroundImage
                      ? 'neutral-100'
                      : 'teal-300'
                  "
                >
                  <Icon
                    icon="icon-user"
                    class="icon-transparent user-login-icon"
                    :color="
                      headerSettings.isBackgroundImage && !menuActive
                        ? 'neutral-100'
                        : headerSettings.isBackgroundImage && menuActive
                        ? 'neutral-100'
                        : !headerSettings.isBackgroundImage && menuActive
                        ? 'neutral-100'
                        : 'violet-1'
                    "
                  />
                  <img
                    v-if="userImageUrl"
                    class="user-photo"
                    :src="userImageUrl"
                    alt="user-photo"
                  />
                </a>
                <Icon
                  icon="icon-arrow-right"
                  class="icon-login-arrow"
                  :color="
                    headerSettings.isBackgroundImage
                      ? 'neutral-100'
                      : 'teal-300'
                  "
                />
                <div
                  v-if="profileMenu.items.length"
                  class="login-dropdown"
                  :class="{ 'login-dropdown--active': isProfileMenu }"
                >
                  <div
                    class="login-list"
                    :class="{ default: !headerSettings.isBackgroundImage }"
                  >
                    <a
                      v-for="menuItem in profileMenu.items"
                      :key="menuItem.id"
                      :href="menuItem.link || '#'"
                      class="login-item login-link"
                      @click="
                        trackEvent(
                          'engagement',
                          'main_menu_click',
                          menuItem.link
                        )
                      "
                    >
                      {{ menuItem.title || "MENU_ITEM" }}
                    </a>
                  </div>

                  <!-- Logout button  -->
                  <a class="logout-btn" :href="tabs.logoutButton.link || '#'">
                    {{ tabs.logoutButton.title || "MENU_ITEM" }}
                  </a>
                  <div class="changeMode" v-if="user.userRole === 'Expert'">
                    <a class="changeMode-btn" :href="changeMode.link || '#'">
                      {{ changeModeTitle }}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!--  Login button -->
          <div
            v-if="!user.isLoggedIn"
            class="header-btn"
            data-test="menu_main_login"
            :class="{ default: !headerSettings.isBackgroundImage }"
            @click="showAuthModal()"
          >
            {{ tabs.loginButton.title || "MENU_ITEM" }}
          </div>

          <a
            v-if="user.isLoggedIn && _isNewDashboard"
            :href="tabs.logoutButton.link ? tabs.logoutButton.link : '#'"
            class="header-btn header-btn--logout"
            :class="{ default: !headerSettings.isBackgroundImage }"
          >
            {{ tabs.logoutButton.title || "MENU_ITEM" }}
          </a>
        </div>
      </div>
    </nav>

    <MenuMobile
      class="menu-mobile"
      :isActive="menuActive"
      :isEnvironmentProduction="isEnvironmentProduction"
      @close="menuActive = false"
      @click-helpme="helpMeOpen"
    />
  </header>
</template>

<script>
import Icon from "@/components/icons/index.vue";
import HeaderButton from "@/components/molecules/header-button/index.vue";
import HeaderBurger from "@/components/atoms/header-burger/index.vue";
import MenuMobile from "@/components/organisms/menu-mobile/index.vue";
import TourSelector from "@/components/molecules/tour-selector/index.vue";
import YtPopup from "@/components/ui-kit/yt-popup";
import popupNotifications from "@/components/organisms/popup-notifications/index.vue";
import Preloader from "@/components/ui-kit/preloader/index.vue";
import { mapMutations, mapState, mapActions } from "vuex";

import Popup from "@/components/organisms/popup/index.vue";
import analyticsEvent from "@/assets/js/utils/analytics-event";

import { ytEvents } from "@/plugins/analytics";
import { i18n } from "@/plugins/i18n";
import { axiosNotifications } from "@/services/scopes/axios-notifications";
import { axiosUtilities} from "@/services/scopes/axios-utilities";
import { ytVueGlobalStore } from "@/services/yt-vue-global-store.js";
import noSSR from "vue-no-ssr";

export default {
  components: {
    Icon,
    HeaderButton,
    HeaderBurger,
    MenuMobile,
    TourSelector,
    popupNotifications,
    Popup,
    YtPopup,
    Preloader,
    "no-ssr": noSSR,
  },
  computed: {
    ...mapState({
      mainScreen: (state) => state.mainScreen.mainScreen,
      currency: (state) => state.currency.currency,
      menu: (state) => state.menu.menu,
      tabs: (state) => state.tabs.tabs,
      user: (state) => state.user,
      headerButtons: (state) => state.headerButtons.headerButtons,
      mainMenu: (state) => state.mainMenu.mainMenu,
      functionalButtons: (state) => state.functionalButtons,
      headerSettings: (state) => state.headerSettings,
      profileMenu: (state) => state.profileMenu,
      changeMode: (state) => state.changeMode,
      facets: (state) => state.serp?.serpTours?.facets?.data,
      notifications: (state) =>
        state.notificationsCenter?.notifications?.new || [],
    }),

    getComponentName() {
      if (this.helpMePopupOpened) {
        return () => import(`@/components/organisms/booking_popups/popup-help-me/index.vue`);
      }
      return "";
    },
    changeModeTitle() {
      return this.changeMode.title || this.$t("profileMenu.regimeChangeTravel");
    },
    isRu() {
      return i18n.locale === 'ru';
    },
    isEn() {
      return i18n.locale === 'en';

    },
    isShowSearch() {
      return (
        (this.headerSettings.isLocationInput ||
          (this.headerSettings.showSearchOnStickyOnly &&
            this.scrollPosition > 500)) &&
        fakeWindow.location?.pathname !== "/"
      );
    },
    isMobileHeader() {
      return fakeWindow.innerWidth <= 943;
    },
    userImageUrl() {

      if (vueOptions.ssr) {
        return false;
      }

      return this.user.user?.imageUrl?.replace("/", "") ? this.user.user.imageUrl : false;
    },
    logoIcon() {
      if (this.isEn) {
        return this.isNotifyPopupOpenMobile
          ? "logo-ts-color"
          : this.headerSettings.isBackgroundImage && !this.menuActive
            ? "logo-ts"
            : !this.headerSettings.isBackgroundImage && !this.menuActive
              ? "logo-ts-color"
              : "logo-ts";
      }

      return this.isNotifyPopupOpenMobile
        ? "logo-youtravel-color"
        : this.headerSettings.isBackgroundImage && !this.menuActive
          ? "logo-youtravel"
          : !this.headerSettings.isBackgroundImage && !this.menuActive
            ? "logo-youtravel-color"
            : "logo-youtravel";
    },
    // FIXME #YT-11814 default dahsbord is new
    _isNewDashboard: () => {
      if (vueOptions.ssr) {
        return false;
      }
      const flags = ytVueGlobalStore.instantGet("flags");
      return flags && flags.onboardingVersion;
    },
  },
  props: {
    isBackgroundImage: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    isInputOpen: false,
    isNotifyPopupOpen: false,
    isNotifyPopupOpenMobile: false,
    isEnvironmentProduction: null,
    scrollPosition: null,
    menuActive: false,
    isProfileMenu: false,
    location: {},
    helpMePopupOpened: false,
    popupLoaded: false,
    locationQueryParams: '',
  }),
  methods: {
    ...mapMutations({
      setNotifications: "notificationsCenter/setNotifications",
      updateNotification: "notificationsCenter/updateNotification",
    }),
    ...mapActions({
      setHeaderButtons: "headerButtons/setHeaderButtons",
      updateMainMenu:   "mainMenu/updateMainMenu",
    }),
    loadedHandler() {
      this.popupLoaded = true;
    },
    updateScroll() {
      this.scrollPosition = fakeWindow.scrollY;
    },
    openNotificationPopup() {
      this.getNotifications();
      this.isNotifyPopupOpen = true;
    },
    openNotificationPopupMobile() {
      if (this.isNotifyPopupOpenMobile) {
        this.isNotifyPopupOpenMobile = false;
      } else {
        this.getNotifications();
        this.isNotifyPopupOpenMobile = true;
      }
    },
    closeNotificationPopup() {
      this.isNotifyPopupOpen = false;
      this.isNotifyPopupOpenMobile = false;
    },
    async acceptNotification(id) {
      axiosNotifications
        .setRead(id)
        .then(({ data }) => {
          if (data.success) {
            this.updateNotification({ id: id, field: "new", value: false });
            fakeWindow.mitt.emit("updateNotification", {
              id: id,
              field: "new",
              value: false,
            });
          }
        })
        .catch((error) => {
          // TODO: нужен тех дизайн обработки ошибок
          console.log(error);
        });
    },
    async acceptAllNotification() {
      axiosNotifications
        .setReadAll()
        .then(({ data }) => {
          if (data.success) {
            this.notifications.forEach((item) => {
              this.updateNotification({
                id: item.id,
                field: "new",
                value: false,
              });
              fakeWindow.mitt.emit("updateNotification", {
                id: item.id,
                field: "new",
                value: false,
              });
            });
          }
        })
        .catch((error) => {
          // TODO: нужен тех дизайн обработки ошибок
          console.log(error);
        });
    },
    async getNotifications() {
      if (this.user.isLoggedIn && this.user.userRole === "Expert") {
        axiosNotifications
          .getNotifications("sort=id&order=desc&limit=10&new=true")
          .then(({ data }) => {
            if (data.success) {
              this.setNotifications({ type: "new", value: data.data });
              fakeWindow.mitt.emit("updateNotification", data.data);
            }
          })
          .catch((error) => {
            // TODO: нужен тех дизайн обработки ошибок
            console.log(error);
          });
      }
    },
    trackEventsFavoriteClick(eventIdentifier) {
      if (eventIdentifier) {
        analyticsEvent(eventIdentifier);
      }
    },
    trackEventsChatClick(eventIdentifier) {
      if (eventIdentifier) {
        analyticsEvent(eventIdentifier);
      }
    },
    trackEventsClick(eventIdentifier) {
      if (eventIdentifier) {
        analyticsEvent(eventIdentifier);
      }
    },
    trackEvent(category, name, label) {
      ytEvents.track(category, name, label);
    },
    handleUserClick() {
      this.trackEvent("Header", "Click Profile");
      if (this.isMobileHeader) {
        this.isProfileMenu = !this.isProfileMenu;
      }
    },
    dropDownMenuItemClick(tabItemOption, event) {

      this.trackEvent("engagement", "main_menu_click", tabItemOption.link);

      if (tabItemOption.trackEvent) {
        this.trackEvent(
          tabItemOption.trackEvent.category,
          tabItemOption.trackEvent.name,
          tabItemOption.link
        );
      }

      if (tabItemOption.TE_REGISTER) {
        event.preventDefault();
        if(i18n.locale === 'ru') {
          const newLkFrontHost = process.env.VUE_APP_NEWLK_FRONT_HOST.replace(/\/$/, '');
          window.open(newLkFrontHost + '/auth/register', '_blank');
        } else {
          this.showAuthModal();
        }
      }
    },
    async linkClick(link, originalKey) {

      let urlLinkObj = new URL(
          link,
          fakeWindow.location.protocol + "//" + fakeWindow.location.hostname
        ),
        urlLinkObjNew = new URL(
          urlLinkObj.pathname + fakeWindow.location?.search,
          urlLinkObj.protocol + "//" + urlLinkObj.hostname
        );

      if (urlLinkObj.searchParams.get(originalKey) !== null) {
        let paramNewValue = urlLinkObj.searchParams.get(originalKey);
        if(urlLinkObjNew.searchParams.get(originalKey) == null) {
          urlLinkObjNew.searchParams.append(
            originalKey,
            paramNewValue
          );
        }
        else {
          urlLinkObjNew.searchParams.set(originalKey,paramNewValue)
        }
      }

      if(originalKey == 'base_currency'
        && ((urlLinkObjNew.searchParams.get('price[from]') !== null
            && urlLinkObjNew.searchParams.get('price[to]') !== null)
          || this.facets)) {

        let priceFrom = urlLinkObjNew.searchParams.get('price[from]') !== null ? urlLinkObjNew.searchParams.get('price[from]') : this.facets.filters.prices.min,
          priceTo = urlLinkObjNew.searchParams.get('price[to]') !== null ? urlLinkObjNew.searchParams.get('price[to]') : this.facets.filters.prices.max,
          currencyFrom = this.currency.id,
          currencyTo = urlLinkObjNew.searchParams.get(originalKey);

        let priceConverted = await axiosUtilities.convertCurrency(JSON.stringify({
          priceFrom,
          priceTo
        }), currencyFrom, currencyTo)
          .then((data) => {
            return data.data.data
          });

        urlLinkObjNew.searchParams.set('price[from]', Math.round(priceConverted.priceFrom))
        urlLinkObjNew.searchParams.set('price[to]', Math.round(priceConverted.priceTo))

        fakeWindow.location.href = urlLinkObjNew.toString();
      }
      else {
        fakeWindow.location.href = urlLinkObjNew.toString();
      }
    },

    submit(location) {
      this.location = location;
      this.locationQueryParams = `${this.location.type}/${this.location.code}`;
      this.$nextTick(() => this.$refs.form.submit());
    },
    showAuthModal() {
      fakeWindow.showVueAuthModal();
      this.trackEvent("Header", "Click Sign-in button");
    },

    helpMeOpen(){
      this.menuActive = false;
      this.helpMePopupOpened = true;
    },
    helpMeClose(){
      this.helpMePopupOpened = false;
    },
    ...mapActions({
      showPopup: "popup/showPopupHandler",
    }),
  },
  mounted() {
    fakeWindow.addEventListener("scroll", this.updateScroll);
    this.getNotifications();
    this.setHeaderButtons({userRole: this.user.userRole}),
      this.updateMainMenu({user: this.user});

  },
  watch: {
    scrollPosition: function () {
      if (this.headerSettings.isSticky) {
        this.$emit("scrollPosition", this.scrollPosition);
      }
    },

  },
};
</script>

<style lang="scss">
//TODO: перенести в глобальный asset
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500&display=swap");

.header-main {
  * {
    font-family: "Inter", sans-serif;
  }
}
</style>

<style lang="scss" scoped>
// TODO: починить иконки или заменить с параметром fill а не stroke
.icon-transparent {
  fill: transparent;
}

.container {
  width: 100%;
  max-width: none;
  margin: 0 auto;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-main {
  position: relative;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 88999;
}

.header-main.default-page {
  background-color: var.$neutral-200;
}

.header-navigation {
  display: flex;

  box-sizing: border-box;
  position: relative;

  padding: 12px 16px;

  align-items: center;

  z-index: 2;

  height: 100%;

  @media (min-width: var.$lg) {
    justify-content: space-between;

    padding: 0 40px;
  }

  &.active {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: max-content;
  }
}

.default-page .header-navigation {
  width: 100%;

  justify-content: space-between;

  @media (min-width: var.$lg) {
    width: 100%;
  }
}

.header-menu-wrapper {
  display: flex;
  align-items: center;
}

.search-tour-wrapper {
  position: relative;

  display: none;

  @media (min-width: var.$lg) {
    display: flex;
    width: 340px;
  }
}

.header-buttons {
  display: none;

  @media (min-width: 1132px) {
    display: flex;
  }
}

.header-tour-selector {
  position: relative;
  z-index: 1;

  max-height: 48px;
  margin-right: 20px;
  margin-left: 20px;

  & >>> {
    .serp-tour-selector {
      height: 48px;
    }

    .serp-tour-selector__icon-remove {
      display: none;
    }
  }
}

.mobile-location-search-btn {
  position: absolute;
  right: 30px;
  top: 52%;
  transform: translate(0, -45%);
  z-index: 2;

  background-color: transparent;
  border: none;

  cursor: pointer;
}

.header-location-form {
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  .hide {
    position: absolute;
    top: 0;
    left: 0;
    height: 0;
    width: 0;
    visibility: collapse;
  }
}

.header-mobile-background {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;

  width: 100%;
  max-width: 0;

  background-color: var.$violet-1;

  transition: max-width 0.2s;
  &.active {
    max-width: 100vw;
  }

  @media (min-width: var.$lg) {
    display: none;
  }
}

.burger-menu {
  margin-top: 5px;
  margin-right: 25px;

  @media (min-width: var.$lg) {
    display: none;

    margin-top: 0;
    margin-right: 0;
  }
}

.logo {
  width: 94px;
  height: 36px;

  z-index: 1;

  &-ts {
    width: 185px;
    height: 36px;

    z-index: 1;
    @media (max-width: var.$lg) {
      width: 94px;
    }

    & > svg {
      width: 185px;
      height: 36px;

      @media (max-width: var.$lg) {
        width: 94px;
      }
    }
  }
}

.dropdown-button {
  display: none;

  margin-right: 16px;

  @media (min-width: var.$lg) {
    display: block;
  }
}

.pickup-tour-btn {
  display: none;

  @media (min-width: var.$lg) {
    display: block;
  }
}

.user-messages-btn {
  position: relative;

  margin-right: 12px;
}

.user-favorites {
  display: block;

  @media (min-width: var.$lg) {
    display: block;
  }
}

.user-favorites-btn {
  position: relative;

  margin-right: 12px;
}

.user-area {
  display: flex;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  padding: 4px 0;

  cursor: pointer;

  @media (min-width: var.$lg) {
    margin-top: 20px;
    padding-bottom: 20px;
  }
}

.user-menu-btn {
  width: 32px;
  height: 32px;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.user-menu-btn svg {
  fill: transparent;
}

.user-notifications {
  @media (min-width: var.$lg) {
    display: block;
    position: relative;
  }
}
.user-notifications-btn {
  position: relative;
  margin-right: 12px;
  display: none;

  @media (min-width: var.$lg) {
    display: flex;
  }
}
.user-notifications-btn.mobile {
  display: flex;
  @media (min-width: var.$lg) {
    display: none;
  }
}
.user-new-notifications {
  background-color: var.$ruby-300;
  position: absolute;
  width: 10px;
  height: 10px;
  right: 6px;
  top: 5px;

  border-radius: 40px;
}
.notifications-round-teal {
  border: 2px solid var.$neutral-200;
}
.notifications-round-white {
  border: 2px solid var.$neutral-100;
}
.user-notification-counter {
  position: absolute;
  bottom: 2px;
  right: -3px;

  width: 20px;
  height: 20px;
  border-radius: 50%;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: var.$green-1;
  color: var.$neutral-100;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
}

.user-login {
  position: relative;

  display: flex;
  align-items: center;

  &:hover {
    .icon-login-arrow {
      transform: rotate(-90deg);
    }
  }

  @media (min-width: var.$lg) {
    &:hover {
      .login-dropdown {
        display: flex;
        max-height: 1000px;
      }
    }
  }
}

.user-login-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.icon-login-arrow {
  display: none;

  transform: rotate(90deg);

  color: transparent;

  transition: transform 0.3s;

  @media (min-width: var.$lg) {
    display: block;
  }
}

.login-dropdown {
  display: none;
  box-sizing: border-box;
  position: absolute;
  top: 110%;
  right: 0;

  flex-direction: column;

  max-height: 0;

  background-color: var.$neutral-100;
  border: 1px solid var.$teal-200;
  border-radius: 8px;

  font-weight: 500;
  font-size: 14px;
  line-height: 18px;

  overflow: hidden;
  z-index: 1;

  &--active {
    display: flex;
    max-height: 1000px;
  }

  @media (min-width: var.$lg) {
    top: 80%;
  }
}

.login-list {
  position: relative;

  padding-bottom: 6px;
  margin: 4px;

  list-style: none;

  &::after {
    position: absolute;
    bottom: 0;
    right: 50%;
    transform: translate(50%);

    width: 90%;
    height: 1px;

    content: "";
    display: block;

    background-color: var.$teal-200;
  }
}

.login-item {
  display: flex;

  border-radius: 8px;

  white-space: nowrap;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background-color: var.$teal-200;
  }
}

.login-link {
  padding: 8px;

  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  text-decoration: none;
  color: var.$teal-500;

  white-space: nowrap;
}

.user-login-btn {
  position: relative;
  overflow: hidden;

  border-radius: 50%;

  @media (min-width: var.$lg) {
    margin-right: 12px;
  }
}

.btn-text {
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  text-transform: capitalize;
}

.logout-btn {
  display: flex;

  padding: 13px;
  margin: 0 4px;
  margin-bottom: 4px;

  background-color: transparent;
  border: none;
  border-radius: 8px;

  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: var.$teal-500;

  text-transform: capitalize;

  white-space: nowrap;
  cursor: pointer;
  color: var.$red;

  &:hover {
    background-color: var.$teal-200;
  }
}

.user-photo {
  z-index: 2;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.header-navigation-wrapper {
  margin: 0;
  padding: 0;

  display: flex;
  align-items: center;

  list-style: none;
}

.header-btn {
  padding: 8px 23px;
  border: 1px solid var.$neutral-100;
  border-radius: 24px;
  font-size: 14px;
  font-weight: 500;
  color: var.$neutral-100;
  cursor: pointer;
}
.header-btn.header-btn--logout {
  @media (max-width: var.$md) {
    padding: 8px 12px;
    font-size: 13px;
  }
}

.header-btn.default {
  padding: 11px 23px;
  border: 1px solid var.$teal-300;
  font-size: 14px;
  color: var.$teal-500;

  transition: 0.3s;

  &:hover {
    background-color: var.$teal-500;
    color: var.$neutral-100;
  }
}

.login-btn {
  @media (min-width: var.$lg) {
    margin: 14px 0;
  }
}

.pick-up-tour-btn {
  margin-right: 56px;
}

.dropdown-list {
  list-style: none;
  padding: 0;
  margin: 4px;
}

.dropdown-item {
  padding: 8px;

  display: flex;

  border-radius: 8px;

  white-space: nowrap;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background-color: var.$teal-200;
  }

  &.active {
    background-color: var.$teal-200;
  }
}

.dropdown-title {
  display: flex;

  color: var.$teal-500;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
}

.dropdown-value {
  display: block;

  padding-left: 16px;
  margin-left: auto;

  color: var.$neutral-400;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
}
.changeMode {
  padding: 12px 0;
  margin: 0 12px;
  border-top: 1px solid var.$teal-200;
}
.changeMode-btn {
  background-color: var.$gray-light;
  border-radius: var.$rounded-8;
  padding: 15px 20px;

  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  text-decoration: none;
  color: var.$teal-500;
  display: flex;

  white-space: nowrap;
  &:hover {
    background-color: var.$teal-200;
  }
}
</style>

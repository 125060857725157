<template>
  <transition name="fade">
    <div class="notify-popup" :class="isOpen ? 'show' : 'hide'">
      <div class="notify-not-empty" v-if="getNewNotifications.length > 0">
        <div class="notify-header">
          <div class="notify-title">{{ $t("notificationsCenter.popupHeader") }}</div>
          <a href="/lk/my-notifications/" class="notify-shaw-all">
            {{ $t("notificationsCenter.popupSeeAll") }}
            <div class="shaw-all__arrow">
              <Icon icon="icon-arrow-right" />
            </div>
          </a>
        </div>
        <div class="notify-body">
          <ul class="notify-list">
            <li
              class="notify-message"
              v-for="notification in getNewNotifications"
              :key="notification.id"
              @click.stop="markAsRead($event, notification.id)"
            >
              <div class="notify-message__item">
                <div
                  class="notify-message__text"
                  v-if="notification.text"
                  v-html="notification.text"
                ></div>
                <div
                  class="notify-message__text"
                  v-else
                  v-html="notification.title"
                ></div>
                <div class="notify-message__actions">
                  <a
                    :href="action.href"
                    class="notify-message__action"
                    v-for="action in notification.actions"
                    :key="action.id"
                  >
                    {{ action.label }}
                  </a>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div class="notify-footer" @click.stop="markAsReadAll">
          <div class="notify-footer__read-all">{{ $t("notificationsCenter.marAllRead") }}</div>
        </div>
      </div>
      <div class="notify-empty" v-else>
        <div class="notify-empty__folder-icon">
          <Icon icon="icon-folder" />
        </div>
        <div class="notify-empty__title">{{ $t("notificationsCenter.noNotificationsTitle") }}</div>
        <div class="notify-empty__text">
          {{ $t("notificationsCenter.noNotificationsText") }}
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import Icon from "@/components/icons/index.vue";
export default {
  components: {
    Icon,
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    notifications: {
      type: Object,
    },
  },
  data: () => ({}),
  computed: {
    getNewNotifications() {
      return this.notifications;
    },
  },
  methods: {
    async markAsRead(evt, id) {
      this.$emit("accept", id);
    },
    markAsReadAll() {
      this.$emit("acceptAll");
    },
  },
};
</script>

<style lang="scss" scoped>
.notify-popup {
  position: fixed;
  top: 0;
  left: 0;
  padding-top: 65px;
  width: 100vw;
  height: 100%;
  background-color: var.$neutral-100;

  @media (min-width: var.$lg) {
    position: absolute;
    background-color: #fff;
    right: 0;
    top: 36px;
    width: fit-content;
    height: auto;
    box-shadow: 2px 2px 20px rgb(0 0 0 / 20%);
    border-radius: var.$rounded-8;
    overflow: hidden;
    padding-top: 0;
    left: auto;
  }
}
.notify-popup.show {
  display: block;
}
.notify-popup.hide {
  display: none;
}
.notify-not-empty {
  width: 100%;

  @media (min-width: var.$lg) {
    width: 440px;
    height: 508px;
    padding-bottom: 48px;
    box-sizing: border-box;
  }
}

.notify-empty {
  width: 440px;
  height: 220px;
  padding: 108px 40px 40px 40px;
  box-sizing: border-box;
  text-align: center;
  margin: auto;

  @media (min-width: var.$lg) {
    padding: 36px 40px 40px 40px;
  }
}

.notify-empty__folder-icon {
  margin-bottom: 20px;
  color: var.$neutral-100;
}

.notify-empty__title {
  color: var.$teal-500;
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 16px;
}

.notify-empty__text {
  color: var.$neutral-500;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
}

.notify-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 24px;
  border-bottom: 1px solid var.$neutral-300;
  padding-top: 24px;
  margin-left: 20px;
  margin-right: 20px;
}

.notify-title {
  font-weight: bold;
  font-size: 16px;
  color: var.$teal-500;
}

.notify-shaw-all {
  color: var.$teal-500;
  font-weight: 600;
  font-size: 14px;
  display: flex;
  align-items: center;
}

.shaw-all__arrow {
  width: 24px;
  height: 24px;
  background-color: var.$neutral-300;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-left: 8px;
}

.notify-body {
  height: 387px;
  overflow-y: scroll;
  margin-right: 4px;

  &::-webkit-scrollbar-thumb {
    background: var.$neutral-400;
  }
  &::-webkit-scrollbar {
    width: 4px;
  }
}

.notify-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.notify-message {
  margin: 0;
  padding: 0;

  &:last-child .notify-message__item {
    border-bottom: none;
  }

  &:hover {
    background-color: var.$neutral-200;
  }
}

.notify-message__item {
  border-bottom: 1px solid var.$neutral-300;
  margin: 0 20px 0 24px;
  padding: 24px 0;
}

.notify-message__text {
  color: var.$teal-500;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 10px;
}

.notify-message__actions {
  display: flex;
  flex-direction: column;
}

.notify-message__action {
  color: var.$green-1;
  font-weight: 700;
  font-size: 14px;
  cursor: pointer;
  line-height: 18px;
  margin-bottom: 5px;
  display: block;
  width: fit-content;
  &:last-child {
    margin-bottom: 0;
  }

  &:hover {
    color: var.$green-2;
  }
}

.notify-footer {
  padding: 15px;
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  background-color: #edeff4;
  cursor: pointer;
}

.notify-footer__read-all {
  text-align: center;
  font-weight: 600;
  font-size: 14px;
  color: #000;
}
</style>

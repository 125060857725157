<template>
  <div
    v-if="showHeader"
    class="header-wrapper js-page-header"
    :class="{
      fixed: fixHeader,
      'background-image': !!headerSettings.isBackgroundImage,
    }"
  >
    <Header
      @scrollPosition="fixHeader = $event > 500"
      :isBackgroundImage="!!headerSettings.isBackgroundImage"
    />
  </div>
</template>

<script>
import Vue from "vue";
import Header from "@/components/organisms/header/index.vue";
import {mapState, mapMutations, mapActions} from "vuex";

import mitt from "mitt";
fakeWindow.mitt = fakeWindow.mitt || new mitt();

Vue.directive("click-outside", {
  bind(el, binding, vnode) {
    el.clickOutsideEvent = (event) => {
      if (!(el === event.target || el.contains(event.target))) {
        vnode.context[binding.expression](event);
      }
    };
    document.body.addEventListener("click", el.clickOutsideEvent);
  },
  unbind(el) {
    document.body.removeEventListener("click", el.clickOutsideEvent);
  },
});

export default {
  name: "header",

  computed: {
    ...mapState({
      headerSettings: (state) => state.headerSettings,
    }),
  },
  components: {
    Header,
  },
  data: () => ({
    fixHeader: false,
    showHeader: true,
  }),
  methods: {
    ...mapMutations({
      setUser: "user/setUser",
    }),
    ...mapActions({
      setHeaderButtons: "headerButtons/setHeaderButtons",
      updateMainMenu:   "mainMenu/updateMainMenu",
      updateProfileMenu: "profileMenu/updateProfileMenu"
    }),
  },
  mounted() {
    fakeWindow.addEventListener("Auth::data", (e) => {
      if (e.detail.user.isLoggedIn) {
        this.setUser(e.detail.user);
        this.setHeaderButtons({userRole: e.detail.user.userRole})
        this.updateMainMenu({user: e.detail.user})
        this.updateProfileMenu({user: e.detail.user});
      }
    });

    fakeWindow.addEventListener("Auth::user", (e) => {
      if (e.detail) {
        this.setUser(e.detail);
        this.setHeaderButtons({userRole: e.detail.userRole})
        this.updateMainMenu({user: e.detail})
        this.updateProfileMenu({user: e.detail});
      }
    });

    fakeWindow.addEventListener("header::show", (e) => {
      this.showHeader = e.detail;
    });
  },
};
</script>

<style lang="scss">
:root {
  --header-height: 64px;
  @media (min-width: var.$lg) {
    --header-height: 72px;
  }
}
.header-wrapper {
  height: var(--header-height, 64px);

  display: flex;
  align-items: center;

  z-index: 100;
  &.fixed {
    position: sticky;
    top: 0;

    background-color: var.$violet-1;
  }
}

.header-wrapper.background-image {
  position: absolute;
  width: 100%;
  z-index: 1000;

  &.fixed {
    position: fixed;
    top: 0;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    background-color: var.$violet-1;
  }
}
</style>

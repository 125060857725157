<template>
  <li class="menu-mobile-tab">
    <a
      v-if="!options.length > 0"
      class="tab-link"
      :href="(!!ajaxData || isOption) ? false : targetLink"
      @click.stop="linkClick()"
    >
      <div class="title-wrapper">
        <Icon
          v-if="iconName"
          class="title-icon"
          color="neutral-100"
          :icon="iconName"
        />
        <span class="tab-title" :class="{ 'tab-title--no-icon': !iconName }">
          {{ title }}
        </span>
      </div>

      <div v-if="+notificationsCount > 0" class="notifications">
        {{ notificationsCount }}
      </div>
    </a>

    <div v-else class="tab-link" @click="$emit('tab-click', $event)">
      <div class="title-wrapper">
        <Icon
          v-if="iconName"
          class="title-icon"
          color="neutral-100"
          :icon="iconName"
        />
        <span class="tab-title">
          {{ title }}
        </span>
      </div>

      <Icon
        v-if="options.length > 0"
        class="title-icon-arrow"
        icon="icon-arrow-right"
        color="neutral-100"
      />
    </div>
  </li>
</template>

<script>
import Icon from "@/components/icons/index.vue";
import { ytEvents } from "@/plugins/analytics";

export default {
  components: {
    Icon,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    targetLink: {
      type: String,
      default: "",
    },
    iconName: {
      type: String,
      default: "",
    },
    notificationsCount: {
      type: String || Number,
      default: "",
    },
    options: {
      type: Boolean,
      default: false,
    },
    ajaxData: {
      type: Object,
      required: false,
    },
    isCounter: {
      type: Boolean,
      default: false,
    },
    isOption: {
      type: Boolean,
      default: false,
    },
    optionKey: {
      type: String,
      default: "",
    },
  },
  methods: {
    trackEvent(category, name, label) {
      ytEvents.track(category, name, label);
    },
    callFunction(functionName) {
      this.$emit("callFunction");
      if (typeof window[functionName] === "function") {
        window[functionName]();
      }
    },
    linkClick() {
      this.trackEvent('engagement', 'main_menu_click', this.link);

      if(this.ajaxData === 'modalHelpMeOpenHelper'){
        this.$emit('click-helpme')
      } else {
        this.ajaxData ? this.callFunction(this.ajaxData) : false;
      }

      if(this.isOption) {
        let urlLink = this.ajaxData ? '' : this.targetLink,
          urlLinkObj = new URL(urlLink, fakeWindow.location.protocol + '//' + fakeWindow.location.hostname), // link
          searchParamsObj = new URLSearchParams(fakeWindow.location.search), // url now
          originalKey = this.optionKey;

        searchParamsObj.forEach(function (value, key) {
          if(originalKey !==  key) {
            if (key.indexOf('[]') !== -1) {
              urlLinkObj.searchParams.append(key, value);
            } else {
              urlLinkObj.searchParams.set(key, value);
            }
          }
        });

        fakeWindow.location.href = urlLinkObj.toString();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.menu-mobile-tab {
  width: 100%;

  padding: 0;
}

.tab-link {
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 12px 0;

  color: var.$neutral-100;
  text-decoration: none;
}

.title-wrapper {
  display: flex;
  align-items: center;
}

.title-icon {
  width: 24px;
  height: 24px;

  display: flex;
  justify-content: center;
  align-items: center;

  margin-right: 12px;
}

.tab-title {
  display: block;

  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  color: var.$neutral-100;

  &--no-icon {
    margin-left: 36px;
  }
}

.title-icon-arrow {
  color: transparent;
}

.notifications {
  width: 24px;
  height: 24px;

  padding: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 50%;
  background-color: var.$green-1;
}
</style>

<template>
  <div
    v-if="isDesktop"
    class="header-button"
    :class="[
      { 'type-tab': type === 'tab', 'type-button': type === 'button' },
      { default: !isBackgroundImage },
      [addCustomClass],
    ]"
    @click.stop="handleClick()"
  >

    <component
      class="heading"
      :is="isSlotted ? 'div' : 'a'"
      :href="!!ajaxData ? false : link"
    >
      <span class="title" :class="{ default: !isBackgroundImage }">
        {{ activeTitle }}
      </span>
      <Icon
        v-if="arrow"
        icon="icon-arrow-right"
        class="title-arrow"
        :color="isBackgroundImage ? 'neutral-100' : 'teal-250'"
        :class="{ active: false, default: !isBackgroundImage }"
      />
    </component>

    <div v-if="isSlotted" class="dropdown-area" :class="[dropdownPosition]">
      <slot name="dropdown"></slot>
    </div>
  </div>
</template>

<script>
import Icon from "@/components/icons/index.vue";
import { ytEvents } from "@/plugins/analytics";
import { isObjectValuesEmpty } from "@/assets/js/utils/is-object-values-empty";

export default {
  components: {
    Icon,
  },
  props: {
    type: {
      validator: function (prop) {
        return ["", "tab", "button"].indexOf(prop) !== -1;
      },
      default: "tab",
    },
    events: {
      type: Object,
      required: false,
    },
    ajaxData: {
      type: Object,
      required: false,
    },
    arrow: {
      type: Boolean,
      default: false,
    },
    activeTitle: {
      type: String,
      default: "#TAB_TITLE",
    },
    link: {
      type: String,
      default: "",
    },
    addCustomClass: {
      type: String,
      default: "",
    },
    dropdownPosition: {
      validator: function (prop) {
        return ["", "left", "right", "center"].indexOf(prop) !== -1;
      },
    },
    isBackgroundImage: {
      type: Boolean,
      default: false,
    },
    isDesktop: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    isSlotted() {
      return !isObjectValuesEmpty(this.$scopedSlots);
    },
  },
  methods: {
    handleClick() {
      if (!this.isSlotted) {
        this.trackEvent("engagement", "main_menu_click", this.link);
        if(this.ajaxData === 'modalHelpMeOpenHelper'){
          this.$emit('click-helpme')
        } else {
          this.ajaxData ? this.callFunction(this.ajaxData) : false;
          this.redirect(this.ajaxData ? false : this.link);
        }
      }
    },
    trackEvent(category, name, label) {
      ytEvents.track(category, name, label);
    },
    callFunction(functionName) {
      if (typeof window[functionName] === "function") {
        window[functionName]();
      }
    },
    redirect(link) {
      if (link) {
        fakeWindow.location.href = link;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.header-button {
  position: relative;

  display: flex;
  flex-direction: column;

  &:hover {
    transition: max-height 0.3s;

    .title-arrow {
      transform: rotate(-90deg);
    }

    .dropdown-area {
      display: block;
      max-height: 1000px;
    }
  }
  &.hide {
    display: none;
  }
}

.heading {
  display: flex;
  align-items: center;
}

.type-button {
  padding: 8px;
  text-align: center;

  border: 1px solid var.$neutral-100;
  border-radius: 24px;

  cursor: pointer;
  transition: 0.3s;

  &:hover {
    background-color: var.$neutral-100;

    .title {
      color: var.$teal-500;
    }
  }
}

.header-button.type-button.default {
  border: 1px solid var.$teal-300;

  &:hover {
    background-color: var.$teal-500;

    .title {
      color: var.$neutral-100;
    }
  }
}

.type-tab {
  padding-bottom: 18px;
  margin-top: 20px;

  cursor: pointer;
}

.title {
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;

  color: var.$neutral-100;

  transition: 0.3s;
}

.title.default {
  color: var.$teal-500;
}

.type-tab .title {
  margin-right: 4px;
}

.title-arrow {
  margin-left: 2px;

  transform: rotate(90deg);

  color: var.$teal-250;

  transition: transform 0.3s;
}

.dropdown-area {
  display: none;
  box-sizing: border-box;
  position: absolute;
  top: 100%;

  max-height: 0px;

  background-color: var.$neutral-100;
  border: 1px solid var.$teal-200;
  border-radius: 8px;

  overflow: hidden;

  &.left {
    left: 0;
  }

  &.right {
    right: 0;
  }

  &.center {
    left: 50%;
    transform: translate(-50%);
  }
}
</style>

import Vue from "vue";
import App from "./app.vue";
import store from "@/store/index";
import {i18n} from "@/plugins/i18n";
import Vuelidate from "vuelidate";

Vue.use(Vuelidate);
export function createApp(__STATE__) {
  if (__STATE__) {
    App.propsData = __STATE__;
  }

  const app = new Vue({
    store,
    i18n,
    render: (h) => h(App),
  });
  return { app };
}

<template>
  <div class="mobile-menu" :class="{ active: isActive }" ref="menu">
    <div class="content">
      <!--   Login & Sign in  -->
      <ul
        class="section login-section"
        :class="{ active: !user.isLoggedIn && isDefaultSectionsActive }"
      >
        <MenuMobileTab
          :title="tabs.loginButton.title"
          :targetLink="tabs.loginButton.link"
        />
        <MenuMobileTab
          :title="tabs.signUpButton.title"
          :targetLink="tabs.signUpButton.link"
        />
      </ul>

      <!--    Language and currency -->
      <ul
        class="section language-currency-section"
        :class="{ active: isDefaultSectionsActive }"
      >
        <MenuMobileTab
          v-if="functionalButtons.currency"
          iconName="icon-currency"
          :title="
            functionalButtons.currency.active.value || '#CURRENCY_MENU_TITLE'
          "
          :options="functionalButtons.currency.options"
          @tab-click="activateCurrencyArea"
        />

        <MenuMobileTab
          v-if="functionalButtons.language"
          iconName="icon-language"
          :title="functionalButtons.language.title || '#LANGUAGE_MENU_TITLE'"
          :options="functionalButtons.language.options"
          @tab-click="activateLanguageArea"
        />
      </ul>

      <!-- Currency area-->
      <div
        class="area"
        :class="{ active: isCurrencyTabActive }"
        v-if="functionalButtons.currency"
      >
        <div class="section-heading" @click="activateDefaultSections">
          <Icon
            class="heading-back-arrow"
            icon="icon-arrow-right"
            color="neutral-100"
          />
          <p class="heading-title">
            {{ functionalButtons.currency.title || "#CURRENCY_AREA_TITLE" }}
          </p>
        </div>
        <ul class="submenu-list">
          <MenuMobileTab
            v-for="currencyMenu of functionalButtons.currency.options"
            :key="currencyMenu.id"
            :title="
              `${currencyMenu.title} ${currencyMenu.value}` ||
              '#CURRENCY_AREA_ITEM'
            "
            :optionKey="currencyMenu.key"
            :targetLink="currencyMenu.link"
            :isOption="currencyMenu.isOption"
          />
        </ul>
      </div>

      <!-- Language area-->
      <div
        class="area"
        :class="{ active: isLanguageTabActive }"
        v-if="functionalButtons.language"
      >
        <div class="section-heading" @click="activateDefaultSections">
          <Icon
            class="heading-back-arrow"
            icon="icon-arrow-right"
            color="neutral-100"
          />
          <p class="heading-title">
            {{ functionalButtons.language.title || "#LANGUAGE_AREA_TITLE" }}
          </p>
        </div>
        <ul class="submenu-list">
          <MenuMobileTab
            v-for="languageItem of functionalButtons.language.options"
            :key="languageItem.id"
            :title="languageItem.title || '#LANGUAGE_AREA_ITEM'"
            :optionKey="languageItem.key"
            :targetLink="languageItem.link"
            :isOption="languageItem.isOption"
          />
        </ul>
      </div>

      <!-- Common area-->
      <div class="area" :class="{ active: isCommonAreaActive }">
        <div class="section-heading" @click="disableArea">
          <Icon class="heading-back-arrow" icon="icon-arrow-right" />
          <p class="heading-title">{{ currentArea.title }}</p>
        </div>
        <ul class="submenu-list">
          <MenuMobileTab
            v-for="currentOption of currentArea.options"
            :key="currentOption.id"
            :title="currentOption.title"
            :targetLink="currentOption.link"
            :notificationsCount="getNotificationsCount(currentOption.link)"
          />
        </ul>
      </div>

      <!-- Tabs list -->
      <ul
        class="section user-section"
        v-if="Object.keys(mainMenu).length > 0"
        :class="{ active: isDefaultSectionsActive }"
      >
        <MenuMobileTab
          v-for="tabsItem of mainMenu"
          :key="tabsItem.id"
          :title="tabsItem.title"
          :iconName="tabsItem.icon"
          :options="tabsItem.options"
          :targetLink="tabsItem.link"
          :notificationsCount="getNotificationsCount(tabsItem.link)"
          :isCounter="tabsItem.isCounter"
          @tab-click="activateArea(tabsItem.options, tabsItem.title)"
        />
      </ul>

      <!--    Menu list -->
      <ul
        class="section user-section"
        v-if="profileMenu.length"
        :class="{ active: isDefaultSectionsActive }"
      >
        <MenuMobileTab
          v-for="menuItem in profileMenu"
          :key="menuItem.id"
          :title="menuItem.title"
          :iconName="menuItem.icon"
          :options="menuItem.options"
          :targetLink="menuItem.link"
          @tab-click="activateArea(menuItem.options, menuItem.title)"
        />
      </ul>

      <!--  Buttons  list -->
      <ul
        class="section pick-tour"
        :class="{ active: isDefaultSectionsActive }"
      >
        <MenuMobileTab
          v-for="headerButton in headerButtons"
          :key="headerButton.id"
          :title="headerButton.title"
          :ajaxData="!!headerButton['on_click'] ? headerButton['on_click'] : ''"
          @tab-click="headerButton.link"
          @callFunction="$emit('close')"
          @click-helpme="$emit('click-helpme')"
        />
      </ul>

      <!--  Bottom row  -->
      <a class="contacts" :href="`tel:${phone.link}`">{{ phone.text }}</a>

      <!-- Logout button -->
      <ul
        v-if="user.isLoggedIn"
        class="section logout"
        :class="{ active: isDefaultSectionsActive }"
      >
        <MenuMobileTab
          :title="tabs.logoutButton.title"
          iconName="icon-exit"
          :targetLink="tabs.logoutButton.link"
        />
      </ul>
    </div>

    <!-- App download button -->
    <a
      class="mobile-application"
      :href="isIos ? tabs.downloadApp.link.apple : tabs.downloadApp.link.google"
      @click="trackEvent('Header', 'Click Download app')"
    >
      <p class="application-description">{{ tabs.downloadApp.title }}:</p>
      <Icon
        class="application-icon"
        :icon="isIos ? 'app-store-button' : 'google-play-button'"
      />
    </a>
  </div>
</template>

<script>
import Icon from "@/components/icons/index.vue";
import MenuMobileTab from "@/components/molecules/menu-mobile-tab/index.vue";
import analyticsEvent from "@/assets/js/utils/analytics-event";
import { mapState } from "vuex";
import { ytEvents } from "@/plugins/analytics";
import { iOS } from "@/assets/js/utils/is-ios";
import { mapActions } from "vuex";

export default {
  components: {
    Icon,
    MenuMobileTab,
  },
  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    isLanguageTabActive: false,
    isCurrencyTabActive: false,
    isDefaultSectionsActive: true,
    isCommonAreaActive: false,
    currentArea: {
      options: "",
      title: "",
    },
  }),
  computed: {
    ...mapState({
      currency: (state) => state.currency.currency,
      language: (state) => state.language.language,
      tabs: (state) => state.tabs.tabs,
      user: (state) => state.user.user,
      menu: (state) => state.menu.menu,
      phone: (state) => state.phone.phone,
      headerButtons: (state) => state.headerButtons.headerButtons,
      mainMenu: (state) => state.mainMenu.mainMenu,
      functionalButtons: (state) => state.functionalButtons,
      profileMenu: (state) => state.profileMenu.items,
    }),
    isIos() {
      return iOS();
    },
  },
  methods: {
    ...mapActions({
      showPopup: "popup/showPopupHandler",
    }),
    getNotificationsCount(link) {
      if (link.includes("favorites")) {
        return this.user.favoritesCount;
      }
    },
    trackEvent(category, name, label) {
      ytEvents.track(category, name, label);
    },
    trackEventsDownloadAppClick(eventIdentifier = undefined) {
      if (eventIdentifier) {
        analyticsEvent(eventIdentifier);
      }
    },
    disableAllSections() {
      this.isLanguageTabActive = false;
      this.isCurrencyTabActive = false;
      this.isTravelsTabActive = false;
      this.isDefaultSectionsActive = false;
      this.isCommonAreaActive = false;
    },
    disableArea() {
      this.isDefaultSectionsActive = true;
      this.isCommonAreaActive = false;
    },
    activateDefaultSections() {
      this.disableAllSections();
      this.isDefaultSectionsActive = true;
    },
    activateLanguageArea() {
      this.disableAllSections();
      this.isLanguageTabActive = true;
    },
    activateCurrencyArea() {
      this.disableAllSections();
      this.isCurrencyTabActive = true;
    },
    activateArea(options, title) {
      this.disableAllSections();
      this.currentArea.options = options;
      this.currentArea.title = title;
      this.isCommonAreaActive = true;
    },
  },
  mounted() {
    fakeWindow.addEventListener("resize", () => {
      this.$refs.menu.style.height = `${fakeWindow.innerHeight}px`;
      this.$refs.menu.style.transition = "none";
    });
  },
  watch: {
    isActive: function () {
      this.$refs.menu.style.transition = "all 0.2s";
    },
  },
};
</script>

<style lang="scss" scoped>
.mobile-menu {
  box-sizing: border-box;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  max-width: 0;
  height: 100%;

  padding-top: 98px;
  padding-bottom: 40px;

  display: flex;
  flex-direction: column;

  overflow-x: hidden;
  overflow-y: scroll;

  background-color: var.$violet-1;
  transition: all 0.2s;

  &.active {
    max-width: 100vw;

    padding: 40px;
    padding-top: 98px;
  }

  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
}

.content {
  width: 100%;
  min-width: 250px;

  margin-bottom: 18px;
}

.section {
  position: absolute;
  right: 100%;
  //width: 100%;
  max-height: 0;

  margin: 0;
  padding: 0;

  display: flex;
  flex-direction: column;
  border-bottom: 1px solid transparent;

  list-style: none;
  overflow: hidden;
  opacity: 0;
  transition: opacity 0.1s, max-height 0s, position 0.3s, right 0.3s;

  &.active {
    position: relative;
    right: 0;

    max-height: 1000px;

    padding: 10px 0;

    border-color: #683b6f;

    opacity: 1;

    &:last-child {
      border-bottom: none;
    }
  }
}

.section.logout {
  margin-bottom: 14px;
}

.area {
  position: absolute;
  left: 100%;
  width: 100%;

  max-height: 0;

  margin: 0;
  padding: 0;

  display: flex;
  flex-direction: column;
  border-bottom: 1px solid transparent;

  list-style: none;
  overflow: hidden;
  opacity: 0;
  transition: opacity 0.1s, max-height 0s, position 0.3s, left 0.3s;

  &.active {
    position: relative;
    left: 0;

    max-height: 1000px;

    padding: 12px 0;

    border-color: #683b6f;

    opacity: 1;
  }
}

.contacts {
  display: block;
  padding: 32px 0;
  color: var.$neutral-100;

  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  text-decoration: none;
}

.mobile-application {
  min-width: 295px;

  margin-top: auto;

  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  text-decoration: none;
}

.application-icon {
  width: 176px;
  width: 100%;
}

.application-description {
  margin-right: 10px;
  padding: 0;

  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  color: var.$neutral-100;
}

.section-heading {
  padding: 12px 0;

  display: flex;
  color: var.$neutral-100;

  align-items: center;

  cursor: pointer;
}

.heading-back-arrow {
  margin-right: 24px;

  transform: rotate(180deg);

  color: var.$neutral-100;
}

.heading-title {
  margin: 0;
  padding: 0;

  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
}

.submenu-list {
  margin: 0;
  padding: 0;

  list-style: none;
}

a.menu-other_lang {
  display: block;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  color: #fff;
  margin-left: 36px;
  padding: 12px 0;
}
</style>

const analyticsEvent = (identifier = undefined) => {
  const regExpIdentifier = /^(\w+?)_(\w+)$/;

  if (identifier) {
    const [, page, place] = identifier.match(regExpIdentifier);

    console.warn("// TrackEvent info:");
    console.group();
    console.warn(`Page: '${page}'`);
    console.warn(`Place: '${place}'`);
    console.groupEnd();

    // TODO: расскомментировать
    // fakeWindow.trackEvent(place, place);
  }
};

export default analyticsEvent;

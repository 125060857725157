import Vue from "vue";
import PortalVue from "portal-vue";
import VScrollLock from "v-scroll-lock";
import { createApp } from "./main";

Vue.use(PortalVue);
Vue.use(VScrollLock, {
  bodyScrollOptions: {
    reserveScrollBarGap: true,
    allowTouchMove: (el) => el.closest(".multiselect__content-wrapper"),
  },
});

const { app } = createApp();

app.$mount("#header");

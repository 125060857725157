function iOS() {
  if(vueOptions.ssr) {
    return false;
  }
  return (
    [
      "iPad Simulator",
      "iPhone Simulator",
      "iPod Simulator",
      "iPad",
      "iPhone",
      "iPod",
    ].includes(navigator.platform) ||
    // iPad on iOS 13 detection
    (/iPad|iPhone|iPod/.test(navigator.platform) || (navigator.platform === 'MacIntel'))
  );
}

export { iOS };

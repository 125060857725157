<template>
  <div
    class="burger"
    :class="[{ active: isActive }, { default: !isBackgroundImage }]"
    @click="$emit('menu-click', $event)"
  >
    <div class="row row-top"></div>
    <div class="row row-center"></div>
    <div class="row row-bottom"></div>
  </div>
</template>

<script>
export default {
  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
    isBackgroundImage: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.burger {
  position: relative;

  box-sizing: border-box;
  width: 24px;
  height: 24px;

  cursor: pointer;

  &.active {
    .row-top {
      width: 110%;

      background-color: var.$neutral-100;

      transform: rotate(45deg);
    }

    .row-center {
      background-color: transparent;
    }

    .row-bottom {
      width: 110%;

      background-color: var.$neutral-100;

      transform: rotate(-45deg);
    }
  }
}

.row {
  width: 100%;
  height: 2px;

  border-radius: 2px;

  background-color: var.$neutral-100;
  transition: all 0.3s;

  &:first-child {
    margin-top: 2px;
  }

  &:last-child {
    margin-bottom: 2px;
  }
}

.burger-menu.default .row {
  background-color: var.$violet-1;
}

.burger-menu.default.active .row-top {
  background-color: var.$neutral-100;
}

.burger-menu.default.active .row-bottom {
  background-color: var.$neutral-100;
}

.row-top {
  top: 0;
  position: absolute;
  transform-origin: top left;
}

.row-center {
  position: absolute;
  top: 50%;

  transform: translate(0, -50%);
}

.row-bottom {
  position: absolute;
  bottom: 0px;
  transform-origin: bottom left;
}
</style>
